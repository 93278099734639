import { t } from '@jotforminc/translation';
import { useDebounce } from '@jotforminc/hooks';
import { elementType, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from '../../../../../../utils/hooks';
import { isYes } from '../../../../../../utils';
import NavigationPanelButton from '../../../../../PublicApp/TopBar/NavigationPanelButton';
import { ScRightPanelBlock, ScRightPanelMobileMenuBlock } from '../../../../styles/scRightPanel';
import RadioToggle from '../../../RadioToggle';
import TextInput from '../../../TextInput';
import { OVERRIDED_ICON_LIST, RightPanelModes } from '../../constants';
import { ScRpMenuDeviceSection } from '../MenuSettingsPanel/ScRpMenuDeviceSection';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import IconSelector from '../../../../../../components/IconSelector';
import SELECTORS from '../../../../../../store/selectors';
import rpDevice from '../../../../../../assets/rpDevice.png';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { ScLineHr } from '../ScLineHr';

const DEFAULT_MENU_TITLE = '';

const NavigationBarProperties = ({
  showNavigationBar,
  DescriptionRenderer,
  mobileMenuIcon,
  showMobileMenuIcon
}) => {
  const dispatch = useDispatch();
  const mobileMenuTitle = useSelector(SELECTORS.getMobileMenuTitle);
  const [_mobileMenuTitle, _setMobileMenuTitle] = useState(mobileMenuTitle);
  const mobileMenuIconWithDefault = mobileMenuIcon || '/cardforms/assets/icons/icon-sets-v2/color/Colored/jfc_icon_color-coloredjfsvg-business-color_hamburgermenu.svg';

  const onPortalPropertyChange = useDebounce(data => dispatch(ACTION_CREATORS.updatePortalAction(data)), 150);
  const onChange = propName => value => onPortalPropertyChange({ [propName]: value });

  const handlePageUpdate = useCallback((propName, value) => {
    if (!value && !isYes(showMobileMenuIcon)) {
      _setMobileMenuTitle(DEFAULT_MENU_TITLE);
      onChange(propName)(DEFAULT_MENU_TITLE);
      return;
    }

    onChange(propName)(value);
  }, [showMobileMenuIcon, onChange]);

  const handleMobileTextChange = useCallback(value => {
    _setMobileMenuTitle(value);
  }, [showMobileMenuIcon]);

  const handleMobileTextBlur = useCallback(({ target: { value } }) => {
    handlePageUpdate('mobileMenuTitle', value);
  }, [handlePageUpdate]);

  const handleMenuIconChange = useCallback(value => {
    if (!isYes(value) && !mobileMenuTitle) {
      onPortalPropertyChange({
        mobileMenuTitle: DEFAULT_MENU_TITLE,
        showMobileMenuIcon: value
      });
      _setMobileMenuTitle(DEFAULT_MENU_TITLE);
      return;
    }

    onChange('showMobileMenuIcon')(value);
  }, [mobileMenuTitle, showMobileMenuIcon, onChange]);

  const handleUnmount = useCallback(() => {
    if (mobileMenuTitle !== _mobileMenuTitle) {
      handlePageUpdate('mobileMenuTitle', _mobileMenuTitle);
    }
  }, [handlePageUpdate, mobileMenuTitle, _mobileMenuTitle]);

  useUnmount(handleUnmount); // handeUnmount function is executed on unmount.

  return (
    <>
      <ScRightPanelBlock line>
        <RadioToggle
          onChange={onChange('showNavigationBar')}
          value={showNavigationBar}
          title={t('Show Navigation Menu')}
        />
      </ScRightPanelBlock>
      <ScLineHr />
      {isYes(showNavigationBar) && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="mobileMenuDisplay">{t('Mobile Menu Display')}</label>
            <DescriptionRenderer style={{ marginTop: 0 }} description={t('Change menu text and icon.')} />
            <ScRpMenuDeviceSection>
              <div className='menuDevice'>
                <img
                  src={rpDevice}
                  alt={t('mobile device')}
                />
                <NavigationPanelButton
                  className="test"
                  mobileMenuTitle={_mobileMenuTitle}
                  mobileMenuIcon={mobileMenuIcon}
                  showMobileMenuIcon={showMobileMenuIcon}
                  isRightPanel={true}
                />
              </div>
            </ScRpMenuDeviceSection>
            <ScRightPanelMobileMenuBlock>
              <label htmlFor="menuName">{t('Mobile Menu Text')}</label>
              <TextInput
                id="menuName"
                onChange={handleMobileTextChange}
                onBlur={handleMobileTextBlur}
                value={_mobileMenuTitle}
              />
            </ScRightPanelMobileMenuBlock>
            <ScRightPanelMobileMenuBlock>
              <RadioToggle
                onChange={handleMenuIconChange}
                value={showMobileMenuIcon}
                title={t('Show Mobile Menu Icon')}
              />
            </ScRightPanelMobileMenuBlock>
            {isYes(showMobileMenuIcon) && (
              <ScRightPanelMobileMenuBlock>
                <label htmlFor="selectIcon">{t('Select Icon')}</label>
                <IconSelector
                  onChange={({ url }) => onChange('mobileMenuIcon')(url)}
                  onRemove={() => onChange('mobileMenuIcon')('')}
                  overriddenIcons={OVERRIDED_ICON_LIST[RightPanelModes.MENU_SETTINGS]}
                  value={mobileMenuIconWithDefault}
                  allowRemove={false}
                />
              </ScRightPanelMobileMenuBlock>
            )}
          </ScRightPanelBlock>
        </>
      )}
    </>
  );
};

NavigationBarProperties.propTypes = {
  showNavigationBar: string,
  DescriptionRenderer: elementType,
  mobileMenuIcon: string,
  showMobileMenuIcon: string
};

NavigationBarProperties.defaultProps = {
  showNavigationBar: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  mobileMenuIcon: '',
  showMobileMenuIcon: ''
};

export default NavigationBarProperties;
